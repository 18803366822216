import React from 'react'
import Convert from './Convert'
import ConvertContext from './ConvertContext'

const fc = () => (
  <ConvertContext>
    <Convert />
  </ConvertContext>
)
export default fc
